/* eslint-disable */
import Swiper from 'swiper/bundle'
import Cookieconsent from 'vanilla-cookieconsent'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import mapboxgl from 'mapbox-gl';
import jssocials from 'jssocials';
import Cookies from 'js-cookie';

export default {
    init() {
        // JavaScript to be fired on all pages

        let scroll = $(window).scrollTop();
        let mapboxgl = require('mapbox-gl/dist/mapbox-gl.js');
        mapboxgl.accessToken = 'pk.eyJ1IjoiYmNuY28iLCJhIjoiY2wxeHEyNm5xMDBvOTNjcmE4bHRzZHJyeiJ9.d4ATsz8VW2qpcDG-Ic9Sbg';

        $(function () {

            $(window).scroll(function () {
                scroll = $(window).scrollTop();
                posHeader();
            });


            // ========================================
            // Header
            // ========================================
            if ($(window).width() <= 1024) {
                $('.js_toggle-menu').on('click', function () {
                    $(this).toggleClass('close');
                    $('body').toggleClass('menu-active');
                });

                $('#header .menu-primary .menu > li.menu-item-has-children > a').on('click', function (e) {
                    e.preventDefault();
                    let $elt = $(this);
                    $elt.closest('li').siblings('li').find('a').removeClass('active');
                    $elt.closest('li').siblings('li').find('.sub-menu').slideUp(400);
                    $elt.toggleClass('active').next('.sub-menu').slideToggle(400);
                });
            }

            $('.js_btn-toggle-search').on('click', function () {
                $('body').toggleClass('search-active');
                $('html').toggleClass('disable-scroll');
                setTimeout(function () {
                    if ($('body').hasClass('search-active')) {
                        $('.search-form .search-field').focus();
                    }
                }, 100);
            });

            let position = $(window).scrollTop();
            let keepHeaderVisible = false;
            let headerSticky = 0;
            let $header = $('#header');
            let headerHeight;

            function posHeader() {
                headerHeight = $header.outerHeight(true);

                if (scroll == 0) {
                    $('body').removeClass('scroll-up scroll-down');
                } else if (scroll > position) {
                    $('body').addClass('scroll-down').removeClass('scroll-up');
                } else {
                    $('body').addClass('scroll-up').removeClass('scroll-down');
                }

                if ($(document).width() > 1024) {
                    if (scroll > position) {
                        // Scroll down
                        if (scroll > headerHeight) {
                            // Cache le header
                            headerSticky = headerHeight;
                            $header.addClass('transition');
                        } else {
                            if (!keepHeaderVisible) {
                                // Cache le header progressivement suivant le scroll
                                headerSticky = scroll;
                                $header.removeClass('transition');
                            }
                        }
                    } else {
                        // Scroll Up
                        if (scroll > headerHeight) {
                            // Affiche le header
                            headerSticky = 0;
                            $header.addClass('transition');
                            keepHeaderVisible = true; // Garde le header visible jusqu'en haut de page
                        } else if (keepHeaderVisible) {
                            headerSticky = 0;
                            $header.addClass('transition');
                        } else {
                            // Affiche le header progressivement suivant le scroll
                            headerSticky = scroll;
                            $header.removeClass('transition');
                        }
                    }
                    if (scroll <= 0) {
                        headerSticky = 0;
                        keepHeaderVisible = false;
                    }

                    $('#header .header-wrapper').css('transform', 'translateY(-' + headerSticky + 'px)');

                    position = scroll;
                } else {
                    $('#header .header-wrapper').css('transform', '');
                }
            }

            posHeader();


            // ========================================
            // Footer
            // ========================================
            $('.js_footer-btn-scroll').on('click', function () {
                $('html,body').stop().animate({
                    scrollTop: 0,
                }, 1500);
            });


            // ========================================
            // Modules
            // ========================================
            // Module Hero
            if ($('.module-hero .item').length > 1) {
                new Swiper('.js_slider-hero', {
                    loop: true,
                    speed: 1000,
                    autoplay: {
                        delay: 4000,
                        disableOnInteraction: true,
                    },
                    navigation: {
                        nextEl: '.js_slider-hero-btn-next',
                        prevEl: '.js_slider-hero-btn-prev',
                    },
                });
            }

            $('.js_slider-hero-btn-scroll').on('click', function () {
                $('html,body').stop().animate({
                    scrollTop: $(this).offset().top + $(this).outerHeight(true),
                }, 1000);
            });


            // Module News slider
            $('.js_news-slider').each(function (index) {
                let elt = this;
                $(elt).siblings('.js_news-slider-btn-prev, .js_news-slider-btn-next').addClass('index-' + index);

                let params = {
                    loop: true,
                    speed: 750,
                    navigation: {
                        nextEl: '.js_news-slider-btn-next.index-' + index,
                        prevEl: '.js_news-slider-btn-prev.index-' + index,
                    },
                };

                if ($(elt).hasClass('columns-2')) {
                    params.spaceBetween = 26;
                    params.slidesPerView = 1;
                    params.breakpoints = {
                        601: {
                            slidesPerView: 2,
                        },
                    };
                } else if ($(elt).hasClass('columns-3')) {
                    params.spaceBetween = 26;
                    params.slidesPerView = 1;
                    params.breakpoints = {
                        601: {
                            slidesPerView: 2,
                        },
                        1025: {
                            slidesPerView: 3,
                        },
                    };
                } else {
                    params.spaceBetween = 26;
                    params.slidesPerView = 'auto';
                }

                new Swiper(elt, params);

            });


            // Module Logos slider
            $('.js_logos-slider').each(function (index) {
                let elt = this;
                $(elt).siblings('.js_logos-slider-btn-prev, .js_logos-slider-btn-next').addClass('index-' + index);
                new Swiper(elt, {
                    loop: true,
                    speed: 750,
                    slidesPerView: 2,
                    spaceBetween: 20,
                    autoplay: {
                        delay: 3000,
                        disableOnInteraction: true,
                    },
                    navigation: {
                        nextEl: '.js_logos-slider-btn-next.index-' + index,
                        prevEl: '.js_logos-slider-btn-prev.index-' + index,
                    },
                    breakpoints: {
                        481: {
                            slidesPerView: 3,
                        },
                        601: {
                            slidesPerView: 4,
                        },
                        961: {
                            slidesPerView: 5,
                            spaceBetween: 26,
                        },
                        1025: {
                            slidesPerView: 6,
                        },
                    },
                });
            });


            // Module Accordion
            $('.js_toggle-accordion').on('click', function () {
                let $elt = $(this);
                let speed = 400;
                if ($elt.hasClass('active')) {
                    $elt.closest('.accordion').find($('.title')).removeClass('active').next('.txt').slideUp(speed);
                } else {
                    $elt.closest('.accordion').find($('.title')).removeClass('active').next('.txt').slideUp(speed);
                    $elt.addClass('active').next('.txt').slideDown(speed);
                }
            });


            // Module Images slider
            let sliderImage = [];
            $('.js_images-slider').each(function (index) {
                let elt = this;
                if ($(elt).find('.item').length > 1) {
                    $(elt).data('index', index); // Utilisé pour la popin du module gallery
                    $(elt).siblings('.js_images-slider-btn-prev, .js_images-slider-btn-next, .js_images-slider-pagination').addClass('index-' + index);

                    sliderImage[index] = new Swiper(elt, {
                        loop: true,
                        speed: 750,
                        navigation: {
                            nextEl: '.js_images-slider-btn-next.index-' + index,
                            prevEl: '.js_images-slider-btn-prev.index-' + index,
                        },
                        pagination: {
                            el: '.js_images-slider-pagination.index-' + index,
                            type: 'bullets',
                            clickable: true,
                        },
                    });
                }
            });


            // Module Video
            $('.js_btn-video').on('click', function () {
                let $elt = $(this);
                let $video = $elt.closest('.video');
                let $iframe = $video.find('iframe');
                let src = 'https://www.youtube-nocookie.com/embed/' + $elt.data('src') + '?rel=0&showinfo=0&enablejsapi=1&autoplay=1';
                $iframe.attr('src', src);
                $video.addClass('active');
                setTimeout(function () {
                    $iframe[0].contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');
                }, 600);
            });


            // Module References
            $('.js_references-slider').each(function () {
                let elt = this;
                if ($(elt).find('.sub-item').length > 1) {
                    new Swiper(elt, {
                        loop: true,
                        speed: 750,
                        allowTouchMove: false,
                        navigation: {
                            nextEl: '.js_references-slider-btn-next',
                            prevEl: '.js_references-slider-btn-prev',
                        },
                        pagination: {
                            el: '.js_references-slider-pagination',
                            type: 'bullets',
                            clickable: true,
                        },
                    });
                }
            });
            $('.js_references-popin-slider').each(function () {
                let elt = this;
                if ($(elt).find('.item').length > 1) {
                    new Swiper(elt, {
                        loop: true,
                        speed: 750,
                        navigation: {
                            nextEl: '.js_references-popin-slider-btn-next',
                            prevEl: '.js_references-popin-slider-btn-prev',
                        },
                        pagination: {
                            el: '.js_references-popin-slider-pagination',
                            type: 'bullets',
                            clickable: true,
                        },
                    });
                }
            });


            // Module Map
            $('.js_load-map').each(function () {
                let elt = this;
                let markers = $(elt).attr('data-markers');
                const map = new mapboxgl.Map({
                    container: $(elt).attr('id'),
                    style: 'mapbox://styles/mapbox/streets-v11',
                    center: [$(elt).attr('data-lng'), $(elt).attr('data-lat')],
                    zoom: 16,
                });
                map.scrollZoom.disable();
                // new mapboxgl.Marker({color: $(elt).attr('data-color')})
                //     .setLngLat([$(elt).attr('data-lng'), $(elt).attr('data-lat')])
                //     .addTo(map);

                var coor = jQuery.parseJSON(markers);
                var bounds = new mapboxgl.LngLatBounds();

                coor.forEach(function (key) {
                    const el = document.createElement('div');
                    el.className = 'marker';
                    el.setAttribute('style', "background-image:url('" + $(elt).attr('data-pin') + "')");
                    // new mapboxgl.Marker({color: $(elt).attr('data-color')})
                    new mapboxgl.Marker(el)
                        .setLngLat([key[0], key[1]])
                        .addTo(map);

                    bounds.extend(key);
                });

                // permet d'ajuster le zoom en fonction des markers
                map.fitBounds(bounds, {padding: 100});
                var mapLat = map.getBounds().getCenter().lat;
                var mapLong = map.getBounds().getCenter().long;
                map.setCenter = (mapLat, mapLong);
                var zoomAjust = coor.length > 1 ? 2 : 1;
                var mapZoom = map.getZoom()
                var newZoom = mapZoom - zoomAjust;
                map.setZoom(newZoom);
            });


            // Module Share
            var url = "http://google.com";
            var text = "Some text to share";

            $("#share").jsSocials({
                // url: url,
                // text: text,
                showLabel: false,
                showCount: false,
                shares: ["email", "twitter", "facebook", "linkedin", "pinterest","whatsapp", "messenger"]
            });


            // ========================================
            // Popin
            // ========================================
            function openPopin(popin, slide) {
                $('.popin-wrapper[data-popin="' + popin + '"]').show(0).addClass('active');
                $('html').addClass('disable-scroll');
                if (slide) {
                    sliderImage[$('.popin-wrapper[data-popin="' + popin + '"]').find('.slider').data('index')].slideTo(slide);
                }
            }

            function closePopin() {
                $('.popin-wrapper').removeClass('active');
                $('html').removeClass('disable-scroll');
                setTimeout(function () {
                    $('.popin-wrapper').hide(0);
                }, 600);
            }

            $(document).on('click', '.js_open-popin', function () {
                openPopin($(this).data('popin'), $(this).data('slide'));
            });


            $(document).on('click', '.js_close-popin', function () {
                closePopin();
            });
            $(document).on('click', '.popin-wrapper', function (e) {
                if (e.target !== this) {
                    return;
                }
                closePopin();
            });


            // Blur on click
            $('a, button, input[type=\'submit\'], .btn').on('click', function () {
                $(this).blur();
            });
        });


        // ========================================
        // Animations
        // ========================================
        gsap.registerPlugin(ScrollTrigger);

        $(window).load(function () {
            gsap.to('.loader img', {duration: 1.1, y: -75, ease: 'power1.inOut'}, '+.5');
            gsap.to('.loader', {duration: .9, opacity: 0}, '+.8');
            gsap.to('.loader', {duration: 0, className: 'loader disable'}, '+1.8');
        });

        $(function () {

            // Main
            gsap.to('#main', {duration: .7, opacity: 1});

            // Pour activer / désactiver les animations
            if (true) {
                if ($('.module.has-background-image.parallax').length > 0) {
                    $('.module.has-background-image.parallax').each(function (index, elt) {
                        gsap.to($(elt).find('.background'), {
                            scrollTrigger: {
                                trigger: elt,
                                scrub: true
                            },
                            top: 0,
                            ease: 'none',
                        });
                    });
                }


                // Module Hero - list
                if ($('.module-hero.mode-list').length > 0) {
                    $('.module-hero.mode-list').each(function (index, elt) {
                        gsap.from($(elt).find('.item:nth-child(1)'), {
                            scrollTrigger: {
                                trigger: elt,
                                start: 'top 80%',
                            },
                            duration: .7,
                            x: -75,
                            opacity: 0,
                        });
                        gsap.from($(elt).find('.item:nth-child(2)'), {
                            scrollTrigger: {
                                trigger: elt,
                                start: 'top 80%',
                            },
                            duration: .7,
                            x: 75,
                            opacity: 0,
                        });
                    });
                }


                // Module Posts list
                if ($('.module-posts-list').length > 0) {
                    $('.module-posts-list').each(function (index, elt) {
                        let tlModulePostList = gsap.timeline({
                            scrollTrigger: {
                                trigger: elt,
                                start: 'top 80%',
                            },
                        });
                        tlModulePostList
                            .from($(elt).find('.title-module'), .7, {y: 25, opacity: 0})
                            .from($(elt).find('.item'), {
                                duration: .7,
                                y: 75,
                                opacity: 0,
                                stagger: 0.1,
                            }, '-=.5')
                    });
                }


                // Module News slider
                if ($('.module-news-slider').length > 0) {
                    $('.module-news-slider').each(function (index, elt) {
                        let tlModuleNewsSlider = gsap.timeline({
                            scrollTrigger: {
                                trigger: elt,
                                start: 'top 80%',
                            },
                        });
                        tlModuleNewsSlider
                            .from($(elt).find('.title-module'), {duration: .7, y: 25, opacity: 0})
                            .from($(elt).find('.slider-wrapper'), {duration: .7, y: 75, opacity: 0}, '-=.5')
                            .from($(elt).find('.btn-more-wrapper'), {duration: .7, y: 75, opacity: 0}, '-=.5')
                    });
                }


                // Module Text image
                if ($('.module-text-image').length > 0) {
                    $('.module-text-image').each(function (index, elt) {
                        gsap.from($(elt).find('.img-left .col-1'), {
                            scrollTrigger: {
                                trigger: elt,
                                start: 'top 80%',
                            },
                            duration: .7,
                            x: -75,
                            opacity: 0,
                        });
                        gsap.from($(elt).find('.img-left .col-2'), {
                            scrollTrigger: {
                                trigger: elt,
                                start: 'top 80%',
                            },
                            duration: .7,
                            x: 75,
                            opacity: 0,
                        });
                        gsap.from($(elt).find('.img-right .col-1'), {
                            scrollTrigger: {
                                trigger: elt,
                                start: 'top 80%',
                            },
                            duration: .7,
                            x: 75,
                            opacity: 0,
                        });
                        gsap.from($(elt).find('.img-right .col-2'), {
                            scrollTrigger: {
                                trigger: elt,
                                start: 'top 80%',
                            },
                            duration: .7,
                            x: -75,
                            opacity: 0,
                        });
                    });
                }


                // Module Text
                if ($('.module-text').length > 0) {
                    $('.module-text').each(function (index, elt) {
                        gsap.from($(elt).find('.txt'), {
                            scrollTrigger: {
                                trigger: elt,
                                start: 'top 80%',
                            },
                            duration: .7,
                            y: 75,
                            opacity: 0,
                        });
                    });
                }


                // Module Head text
                if ($('.module-head-text').length > 0) {
                    $('.module-head-text').each(function (index, elt) {
                        gsap.from($(elt).find('.col'), {
                            scrollTrigger: {
                                trigger: elt,
                                start: 'top 80%',
                            },
                            duration: .7,
                            y: 75,
                            opacity: 0,
                            stagger: 0.1,
                        });
                    });
                }


                // Module Logos slider
                if ($('.module-logos-slider').length > 0) {
                    $('.module-logos-slider').each(function (index, elt) {
                        let tlModuleLogosSlider = gsap.timeline({
                            scrollTrigger: {
                                trigger: elt,
                                start: 'top 80%',
                            },
                        });
                        tlModuleLogosSlider
                            .from($(elt).find('.title-module'), {duration: .7, y: 25, opacity: 0})
                            .from($(elt).find('.slider-wrapper'), {duration: .7, y: 75, opacity: 0}, '-=.5')
                    });
                }


                // Module Contact
                if ($('.module-contact').length > 0) {
                    $('.module-contact').each(function (index, elt) {
                        let tlModuleContact = gsap.timeline({
                            scrollTrigger: {
                                trigger: elt,
                                start: 'top 80%',
                            },
                        });
                        tlModuleContact
                            .from($(elt).find('.title-module'), {duration: .7, y: 25, opacity: 0})
                            .from($(elt).find('.col-1'), {duration: .7, x: -75, opacity: 0}, '-=.5')
                            .from($(elt).find('.col-2'), {duration: .7, x: 75, opacity: 0}, '-=.7')
                    });
                }


                // Module Form
                if ($('.module-form').length > 0) {
                    $('.module-form').each(function (index, elt) {
                        let tlModuleForm = gsap.timeline({
                            scrollTrigger: {
                                trigger: elt,
                                start: 'top 80%',
                            },
                        });
                        tlModuleForm
                            .from($(elt).find('.title-module'), {duration: .7, y: 25, opacity: 0})
                            .from($(elt).find('.gfield'), {
                                duration: .7,
                                y: 75,
                                opacity: 0,
                                stagger: 0.1,
                            }, '-=.5')
                            .from($(elt).find('.gform_footer'), {duration: .7, y: 25, opacity: 0})
                    });
                }


                // Module Map
                if ($('.module-map').length > 0) {
                    $('.module-map').each(function (index, elt) {
                        let tlModuleMap = gsap.timeline({
                            scrollTrigger: {
                                trigger: elt,
                                start: 'top 80%',
                            },
                        });
                        tlModuleMap
                            .from($(elt).find('.title-module'), {duration: .7, y: 25, opacity: 0})
                            .from($(elt).find('.map-wrapper'), {duration: .7, opacity: 0}, '-=.5')
                    });
                }


                // Module Accordion
                if ($('.module-accordion').length > 0) {
                    $('.module-accordion').each(function (index, elt) {
                        let tlModuleAccordion = gsap.timeline({
                            scrollTrigger: {
                                trigger: elt,
                                start: 'top 80%',
                            },
                        });
                        tlModuleAccordion
                            .from($(elt).find('.title-module'), {duration: .7, y: 25, opacity: 0})
                            .from($(elt).find('.title'), {
                                duration: .7,
                                x: 75,
                                opacity: 0,
                                stagger: 0.1,
                            }, '-=.5')
                    });
                }


                // Module Images slider
                if ($('.module-images-slider').length > 0) {
                    $('.module-images-slider').each(function (index, elt) {
                        let tlModuleImagesSlider = gsap.timeline({
                            scrollTrigger: {
                                trigger: elt,
                                start: 'top 80%',
                            },
                        });
                        tlModuleImagesSlider
                            .from($(elt).find('.title-module'), {duration: .7, y: 25, opacity: 0})
                            .from($(elt).find('.slider-wrapper'), {duration: .7, y: 75, opacity: 0}, '-=.5')
                    });
                }


                // Module Vidéo
                if ($('.module-video').length > 0) {
                    $('.module-video').each(function (index, elt) {
                        let tlModuleVideo = gsap.timeline({
                            scrollTrigger: {
                                trigger: elt,
                                start: 'top 80%',
                            },
                        });
                        tlModuleVideo
                            .from($(elt).find('.title-module'), {duration: .7, y: 25, opacity: 0})
                            .from($(elt).find('.video'), {duration: .7, y: 75, opacity: 0}, '-=.5')
                    });
                }


                // Module Quote
                if ($('.module-quote').length > 0) {
                    $('.module-quote').each(function (index, elt) {
                        let tlModuleQuote = gsap.timeline({
                            scrollTrigger: {
                                trigger: elt,
                                start: 'top 80%',
                            },
                        });
                        tlModuleQuote
                            .from($(elt).find('.title-module'), {duration: .7, y: 25, opacity: 0})
                            .from($(elt).find('.txt'), {duration: .7, y: 75, opacity: 0}, '-=.5')
                            .from($(elt).find('.illus-wrapper'), {duration: .7, y: 75, opacity: 0}, '-=.6')
                            .from($(elt).find('.name'), {duration: .7, y: 75, opacity: 0}, '-=.6')
                            .from($(elt).find('.function'), {duration: .7, y: 75, opacity: 0}, '-=.6')
                    });
                }


                // Module References
                if ($('.module-references').length > 0) {
                    $('.module-references').each(function (index, elt) {
                        let tlModuleReferences = gsap.timeline({
                            scrollTrigger: {
                                trigger: elt,
                                start: 'top 80%',
                            },
                        });
                        tlModuleReferences
                            .from($(elt).find('.title-module'), {duration: .7, y: 25, opacity: 0})
                            .from($(elt).find('.tabs'), {duration: .7, y: 75, opacity: 0}, '-=.5')
                            .from($(elt).find('.list .item'), {
                                duration: .7,
                                y: 75,
                                opacity: 0,
                                stagger: 0.1,
                            }, '-=.5')
                            .from($(elt).find('.btn-more-wrapper'), {duration: .7, y: 75, opacity: 0}, '-=.5')
                    });
                }


                // Module Gallery
                if ($('.module-gallery').length > 0) {
                    $('.module-gallery').each(function (index, elt) {
                        let tlModuleGallery = gsap.timeline({
                            scrollTrigger: {
                                trigger: elt,
                                start: 'top 80%',
                            },
                        });
                        tlModuleGallery
                            .from($(elt).find('.title-module'), {duration: .7, y: 25, opacity: 0})
                            .from($(elt).find('.tabs'), {duration: .7, y: 75, opacity: 0}, '-=.5')
                            .from($(elt).find('.list .item'), {
                                duration: .7,
                                y: 75,
                                opacity: 0,
                                stagger: 0.1,
                            }, '-=.6')
                    });
                }


                // Module Icons
                if ($('.module-icons').length > 0) {
                    $('.module-icons').each(function (index, elt) {
                        let tlModuleIcons = gsap.timeline({
                            scrollTrigger: {
                                trigger: elt,
                                start: 'top 80%',
                            },
                        });
                        tlModuleIcons
                            .from($(elt).find('.title-module'), {duration: .7, y: 25, opacity: 0})
                            .from($(elt).find('.list .item'), {
                                duration: .7,
                                y: 75,
                                opacity: 0,
                                stagger: 0.1,
                            }, '-=.5')
                    });
                }


                // Module Link
                if ($('.module-link').length > 0) {
                    $('.module-link').each(function (index, elt) {
                        let tlModuleLink = gsap.timeline({
                            scrollTrigger: {
                                trigger: elt,
                                start: 'top 80%',
                            },
                        });
                        tlModuleLink
                            .from($(elt).find('.btn-wrapper'), {duration: .7, y: 75, opacity: 0})
                    });
                }


                // PAGE ARCHIVE News
                if ($('.page-archive-news').length > 0) {
                    $('.page-archive-news').each(function (index, elt) {
                        gsap.from($(elt).find('.title-page-wrapper'), {
                            scrollTrigger: {
                                trigger: elt,
                                start: 'top 80%',
                            },
                            duration: .7,
                            y: 25,
                            opacity: 0,
                        });
                        gsap.from($(elt).find('.tabs'), {
                            scrollTrigger: {
                                trigger: elt,
                                start: 'top 80%',
                            },
                            duration: .7,
                            y: 75,
                            opacity: 0,
                        });
                        gsap.from($(elt).find('.list-single .item'), {
                            scrollTrigger: {
                                trigger: elt,
                                start: 'top 80%',
                            },
                            duration: .7,
                            y: 75,
                            opacity: 0,
                            stagger: 0.1,
                        });
                    });
                }


                // PAGE ARCHIVE References
                if ($('.page-archive-references').length > 0) {
                    $('.page-archive-references').each(function (index, elt) {
                        gsap.from($(elt).find('.title-page-wrapper'), {
                            scrollTrigger: {
                                trigger: elt,
                                start: 'top 80%',
                            },
                            duration: .7,
                            y: 25,
                            opacity: 0,
                        });
                    });
                }

            }

            // Module Clickable
            $('.module-clickable .items').each(function (index, elt) {
                  let tlModuleClickable = gsap.timeline({
                      scrollTrigger: {
                          trigger: elt,
                          start: 'top 80%',
                      },
                  });
                  tlModuleClickable
                      .from($(elt).prev('.title-module'), .7, {y: 25, opacity: 0})
                      .from($(elt).find('.item'), {
                          duration: .7,
                          y: 75,
                          opacity: 0,
                          stagger: 0.1,
                      }, '-=.5')
              });            

            // ========================================
            // Cookie
            // ========================================
            let cc = initCookieConsent();

            // run plugin with your configuration
            cc.run({
                current_lang: 'fr',

                gui_options: {
                    consent_modal: {
                        layout: 'box',              // box/cloud/bar
                        position: 'bottom left',    // bottom/middle/top + left/right/center
                        transition: 'slide',        // zoom/slide
                    },
                    settings_modal: {
                        layout: 'box',              // box/bar
                        // position : 'left',       // left/right
                        transition: 'slide',        // zoom/slide
                    },
                },
                // si les cookies sont acceptés (ne pas confondre avec le fait de cliquer sur accepter)
                onAccept: function (cookie) {
                    document.querySelector('.cookies-button').classList.add('is-visible');
                },
                onFirstAction: function (cookie) {
                    window.location.reload();
                },
                onChange: function (cookie) {
                    window.location.reload();
                },

                languages: {
                    'fr': {
                        consent_modal: {
                            title: 'Hello ! On vous propose des <span class="large">Cookies</span>',
                            description: 'Ce site utilise des cookies essentiels pour assurer son bon fonctionnement et des cookies de suivi pour comprendre comment vous interagissez avec lui. Ce dernier ne sera fixé qu\'après accord. <button type="button" data-cc="c-settings" class="cc-link">Choisir mes préférences</button>',
                            primary_btn: {
                                text: 'Tout accepter',
                                role: 'accept_all',             // 'accept_selected' or 'accept_all'
                            },
                            secondary_btn: {
                                text: 'Tout rejeter',
                                role: 'accept_necessary',        // 'settings' or 'accept_necessary'
                            },
                        },
                        settings_modal: {
                            title: 'Gestion de préférence des cookies',
                            save_settings_btn: 'Enregistrer',
                            accept_all_btn: 'Tout accepter',
                            reject_all_btn: 'Tout rejeter',
                            close_btn_label: 'Fermer',
                            cookie_table_headers: [
                                {col1: 'Nom'},
                                {col2: 'Domaine'},
                                {col3: 'Expiration'},
                                {col4: 'Description'},
                            ],
                            blocks: [
                                {
                                    // title: 'Cookie usage 📢',
                                    description: 'Sur ce site, nous utilisons des cookies pour mesurer notre audience. Vous pouvez sélectionner ici les services que vous allez autoriser.',
                                }, {
                                    title: 'Cookies nécessaires au bon fonctionnement du site',
                                    description: 'Ces cookies sont indispensables au bon fonctionnement du site internet. Ces cookies sont essentiels au bon fonctionnement du site, vous ne pouvez pas les désactiver.',
                                    toggle: {
                                        value: 'necessary',
                                        enabled: true,
                                        readonly: true,
                                    },
                                }, {
                                    title: 'Publicitaires (ou presque)',
                                    description: 'Pour concevoir ce site nous avons fait appel à une société bien de chez nous (à Nîmes pour être précis) et cette société s’appuie pour rendre votre site plus dynamique sur des technologies tierces : YouTube, openmap, etc… Pour profiter de l’ensemble des technologies nous vous invitons à accepter ces cookies.',
                                    toggle: {
                                        value: 'advertising',
                                        enabled: false,
                                        readonly: false,
                                    },
                                }, {
                                    title: 'Google Analytics',
                                    description: 'Ces cookies permettent d\'analyser les statistiques de consultation de notre site.',
                                    toggle: {
                                        value: 'analytics',
                                        enabled: false,
                                        readonly: false,
                                    },
                                    // cookie_table: [             // list of all expected cookies
                                    //     {
                                    //         col1: '^_ga',       // match all cookies starting with "_ga"
                                    //         col2: 'google.com',
                                    //         col3: '2 years',
                                    //         col4: 'description ...',
                                    //         is_regex: true
                                    //     },
                                    //     {
                                    //         col1: '_gid',
                                    //         col2: 'google.com',
                                    //         col3: '1 day',
                                    //         col4: 'description ...',
                                    //     }
                                    // ]
                                    // }, {
                                    //     title: 'Advertisement and Targeting cookies',
                                    //     description: 'These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you',
                                    //     toggle: {
                                    //         value: 'targeting',
                                    //         enabled: false,
                                    //         readonly: false
                                    //     }
                                    // }, {
                                    //     title: 'More information',
                                    //     description: 'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="#yourcontactpage">contact us</a>.',
                                },
                            ],
                        },
                    },
                },
            });
        });
        $(window).on('load', function() {

            // Anchor for Chrome
            var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
            if (window.location.hash && isChrome) {
                setTimeout(function () {
                    var hash = window.location.hash;
                    window.location.hash = "";
                    window.location.hash = hash;
                }, 300);
            }

        });
    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
